<template>
  <v-card
    v-if="member.playlist"
    class="playlist-container"
    color="#212222"
    data-testid="container-playlist"
  >
    <v-card-title>
      <span data-testid="playlist-name">
        {{ activePlaylist.name }}
      </span>
      <v-spacer></v-spacer>
      <span
        v-if="rcEngineData"
        :class="{
          [Pallette.statusText.active]: rcEngineData.is_active,
          [Pallette.statusText.notActive]: !rcEngineData.is_active,
          'font-weight-regular': true,
        }"
        data-testid="playlist-status"
      >
        {{ rcEngineData.is_active ? "active" : "inactive" }}
      </span>
    </v-card-title>
    <div
      v-if="activePlaylist.parsedItems && activePlaylist.parsedItems.length > 0"
    >
      <template v-for="(item, index) in activePlaylist.parsedItems">
        <div
          class="track"
          :class="{ track: true, track_active: trackIsActive(item) }"
          :key="'playlist-item' + index"
          :data-testid="'playlist-item' + index"
        >
          <div class="track__wrap d-flex justify-space-between align-center">
            <div class="track__first d-flex flex-column align-center">
              <div class="track__number">
                {{ trackOrder(index + 1) }}
              </div>
              <div class="align-self-end track__status-icon">
                <v-icon size="18">mdi-video-image</v-icon>
              </div>
            </div>
            <div class="track__second">
              <div
                class="d-flex align-center justify-center track__img-wrap"
                style="
                  background: url('/images/100x72-808080.png') no-repeat fixed
                    center;
                  background-size: 100% 100%;
                  width: 100px;
                  height: 72px;
                "
              >
                <v-btn
                  icon
                  color="white"
                  v-if="
                    trackIsActive(item) && activePlaylist.status !== 'Runing'
                  "
                  @click="startItemEvent(activePlaylist, rcEngineData)"
                  :disabled="!rcEngineData.is_active"
                >
                  <v-icon>mdi-play-circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="white"
                  v-if="!trackIsActive(item)"
                  @click="playItemEvent(item.pos, activePlaylist, rcEngineData)"
                  :disabled="!rcEngineData.is_active"
                >
                  <v-icon>mdi-play-circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="white"
                  v-if="
                    trackIsActive(item) && activePlaylist.status === 'Runing'
                  "
                  @click="pauseItemEvent(activePlaylist, rcEngineData)"
                  :disabled="!rcEngineData.is_active"
                >
                  <v-icon>mdi-pause-circle</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="track__third d-flex flex-column text-left flex-grow-1">
              <div class="track__name">
                {{ item.name }}
              </div>
              <div class="track__artist">
                {{
                  member.location.name
                    ? member.location.name
                    : member.engine
                    ? member.engine.name
                    : ""
                }}
              </div>
              <div class="track__timer">5:23</div>
            </div>
            <div class="track__forth">
              <v-icon size="18" @click="&quot;javascript:void(0)&quot;"
                >mdi-dots-horizontal</v-icon
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import { pad } from "@/common/general.service"
import EventPlaylistsMixin from "@/mixins/event/playlists.js"
import { mapGetters } from "vuex"

export default {
  mixins: [EventPlaylistsMixin],

  methods: {
    trackOrder(index) {
      return pad(index)
    },
    trackIsActive(item) {
      return parseInt(this.activePlaylist.index) === parseInt(item.pos)
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
    }),
  },
}
</script>
