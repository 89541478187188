import { mapGetters } from "vuex"
import RCPlaylistsMixin from "@/mixins/remote-control/rc-playlists"
import WsConnMixin from "@/mixins/websocket-connection.js"

export default {
  props: {
    member: {
      type: Object,
      default: null,
    },
  },

  mixins: [RCPlaylistsMixin, WsConnMixin],

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
    }),
    activePlaylist() {
      let rcData = this?.member?.rcEngineData
      if (rcData && rcData.playlists.length > 0) {
        return rcData.playlists[0]
      } else {
        return []
      }
    },
    rcEngineData() {
      return this.member?.rcEngineData
    },
  },
}
