<template>
  <div
    class="d-flex align-center"
    style="overflow-x: auto; overflow-y: hidden"
    data-testid="container-playlists-volumes"
  >
    <div
      v-for="(member, index) in membersEvent"
      class="mr-2"
      :key="'member-event-' + index"
    >
      <PlaylistVolume v-if="member.playlist" :member="member" />
    </div>
  </div>
</template>

<script>
import PlaylistVolume from "./PlaylistVolume"
import { mapGetters } from "vuex"

export default {
  components: {
    PlaylistVolume,
  },

  data: () => ({
    //Slider configuration [obj]
    options: {
      currentPage: 0,
      thresholdDistance: "400",
      loopedSlides: 2,
    },
  }),

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
    }),
    membersEvent: {
      get() {
        return this.activeEvent.members
      },
      set(val) {},
    },
  },
}
</script>
