<template>
  <v-card
    class="mx-auto"
    max-width="600"
    min-width="250"
    width="250"
    height="72"
    v-if="member.playlist"
    data-testid="container-playlist-volume"
  >
    <v-card-text class="pt-2">
      <v-row>
        <v-col class="">
          <div
            class="d-flex subtitle-2 px-1 justify-space-between align-center"
          >
            <span class="font-weight-bold">{{ activePlaylist.name }}</span>
            <div class="d-flex align-center">
              <v-tooltip bottom v-if="realMute">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-show="realMute"
                    color="gray"
                    @click="realMute = false"
                    :disabled="!rcEngineData || !rcEngineData.is_active"
                    size="16"
                    class="mr-2"
                    v-on="on"
                    data-testid="btn-unmute-playlist-volume"
                  >
                    mdi-volume-off
                  </v-icon>
                </template>
                Unmute playlist volume
              </v-tooltip>
              <v-tooltip bottom v-if="!realMute">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-show="!realMute"
                    color="gray"
                    @click="realMute = true"
                    :disabled="!rcEngineData || !rcEngineData.is_active"
                    size="16"
                    class="mr-2"
                    v-on="on"
                    data-testid="btn-mute-playlist-volume"
                  >
                    mdi-volume-high
                  </v-icon>
                </template>
                Mute playlist volume
              </v-tooltip>
              <span class="font-weight-bold">{{ volume }}</span>
            </div>
          </div>
          <v-slider
            v-model="realVolume"
            :color="color"
            track-color="grey"
            min="0"
            max="100"
            dense
            class="playlist-volume"
            hide-details
            :disabled="!rcEngineData || !rcEngineData.is_active"
          >
            <template v-slot:prepend>
              <v-icon
                :color="color"
                @click="decrement"
                :disabled="!rcEngineData || !rcEngineData.is_active"
                data-testid="btn-decrement-playlist-volume"
              >
                mdi-volume-minus
              </v-icon>
            </template>

            <template v-slot:append>
              <v-icon
                :color="color"
                @click="increment"
                :disabled="!rcEngineData || !rcEngineData.is_active"
                data-testid="btn-increment-playlist-volume"
              >
                mdi-volume-plus
              </v-icon>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import EventPlaylistsMixin from "@/mixins/event/playlists.js"
import debounce from "lodash.debounce"

export default {
  mixins: [EventPlaylistsMixin],

  data: () => ({
    volume: 0,
  }),

  watch: {
    volume: {
      handler: debounce(function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.setVolumePlaylistEvent(
            this.activePlaylist,
            this.member?.rcEngineData,
            newVal,
            this.realMute
          )
        }
      }, 800),
      deep: true,
    },
  },

  methods: {
    decrement() {
      this.volume--
    },
    increment() {
      this.volume++
    },
  },

  computed: {
    color() {
      if (this.volume < 20) return "indigo"
      if (this.volume < 40) return "teal"
      if (this.volume < 60) return "green"
      if (this.volume < 80) return "orange"
      return "red"
    },
    realVolume: {
      get() {
        return typeof this.activePlaylist?.volume !== "undefined"
          ? this.activePlaylist?.volume
          : 0
      },
      set(val) {
        this.volume = val
      },
    },
    realMute: {
      get() {
        return typeof this.activePlaylist?.mute !== "undefined"
          ? this.activePlaylist?.mute
          : false
      },
      set(val) {
        this.setVolumePlaylistEvent(
          this.activePlaylist,
          this.member?.rcEngineData,
          this.realVolume,
          val
        )
      },
    },
  },
}
</script>
