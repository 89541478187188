var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.member.playlist)?_c('v-card',{staticClass:"playlist-container",attrs:{"color":"#212222","data-testid":"container-playlist"}},[_c('v-card-title',[_c('span',{attrs:{"data-testid":"playlist-name"}},[_vm._v(" "+_vm._s(_vm.activePlaylist.name)+" ")]),_c('v-spacer'),(_vm.rcEngineData)?_c('span',{class:{
        [_vm.Pallette.statusText.active]: _vm.rcEngineData.is_active,
        [_vm.Pallette.statusText.notActive]: !_vm.rcEngineData.is_active,
        'font-weight-regular': true,
      },attrs:{"data-testid":"playlist-status"}},[_vm._v(" "+_vm._s(_vm.rcEngineData.is_active ? "active" : "inactive")+" ")]):_vm._e()],1),(_vm.activePlaylist.parsedItems && _vm.activePlaylist.parsedItems.length > 0)?_c('div',[_vm._l((_vm.activePlaylist.parsedItems),function(item,index){return [_c('div',{key:'playlist-item' + index,staticClass:"track",class:{ track: true, track_active: _vm.trackIsActive(item) },attrs:{"data-testid":'playlist-item' + index}},[_c('div',{staticClass:"track__wrap d-flex justify-space-between align-center"},[_c('div',{staticClass:"track__first d-flex flex-column align-center"},[_c('div',{staticClass:"track__number"},[_vm._v(" "+_vm._s(_vm.trackOrder(index + 1))+" ")]),_c('div',{staticClass:"align-self-end track__status-icon"},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-video-image")])],1)]),_c('div',{staticClass:"track__second"},[_c('div',{staticClass:"d-flex align-center justify-center track__img-wrap",staticStyle:{"background":"url('/images/100x72-808080.png') no-repeat fixed","background-size":"100% 100%","width":"100px","height":"72px"}},[(
                  _vm.trackIsActive(item) && _vm.activePlaylist.status !== 'Runing'
                )?_c('v-btn',{attrs:{"icon":"","color":"white","disabled":!_vm.rcEngineData.is_active},on:{"click":function($event){return _vm.startItemEvent(_vm.activePlaylist, _vm.rcEngineData)}}},[_c('v-icon',[_vm._v("mdi-play-circle")])],1):_vm._e(),(!_vm.trackIsActive(item))?_c('v-btn',{attrs:{"icon":"","color":"white","disabled":!_vm.rcEngineData.is_active},on:{"click":function($event){return _vm.playItemEvent(item.pos, _vm.activePlaylist, _vm.rcEngineData)}}},[_c('v-icon',[_vm._v("mdi-play-circle")])],1):_vm._e(),(
                  _vm.trackIsActive(item) && _vm.activePlaylist.status === 'Runing'
                )?_c('v-btn',{attrs:{"icon":"","color":"white","disabled":!_vm.rcEngineData.is_active},on:{"click":function($event){return _vm.pauseItemEvent(_vm.activePlaylist, _vm.rcEngineData)}}},[_c('v-icon',[_vm._v("mdi-pause-circle")])],1):_vm._e()],1)]),_c('div',{staticClass:"track__third d-flex flex-column text-left flex-grow-1"},[_c('div',{staticClass:"track__name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"track__artist"},[_vm._v(" "+_vm._s(_vm.member.location.name ? _vm.member.location.name : _vm.member.engine ? _vm.member.engine.name : "")+" ")]),_c('div',{staticClass:"track__timer"},[_vm._v("5:23")])]),_c('div',{staticClass:"track__forth"},[_c('v-icon',{attrs:{"size":"18"},on:{"click":function($event){"javascript:void(0)"}}},[_vm._v("mdi-dots-horizontal")])],1)])])]})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }