<template>
  <div id="filtered-events-wrap" data-app="true">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      dark
      persistent
      attach="#filtered-events-wrap"
    >
      <v-card
        class="event-filtered-control d-flex flex-column"
        color="#2F3132"
        style="padding: 0"
        data-testid="container-events-control"
      >
        <v-toolbar
          color="#2F3132"
          dark
          class="event-filtered-control__title pa-0"
          elevation="5"
          style="flex-grow: 0"
        >
          <div class="event-filtered-control__date-time">
            <span
              class="event-hour event-duration-number"
              data-testid="event-start-datetime"
            >
              {{
                activeEvent.start_date
                  | unixDateTimeFormat("MMM D, YYYY h:mm a")
              }}
            </span>
          </div>
          <div class="event-filtered-control__duration">
            <span
              class="event-duration-number event-filtered-control__duration-number"
              data-testid="event-duration"
            >
              {{ activeEvent.duration }}
            </span>
          </div>
          <div class="event-filtered-control__img">
            <v-avatar data-testid="event-icon">
              <img :src="activeEvent.icon" size="38" alt="event" />
            </v-avatar>
          </div>
          <div
            class="event-name event-filtered-control__name"
            data-testid="event-name"
          >
            {{ activeEvent.name }}
          </div>
          <div
            class="event-name event-filtered-control__status d-flex justify-space-between"
          >
            <EventStatusChip :event="activeEvent" data-testid="event-status" />
            <v-btn icon @click="closeDialog()" data-testid="btn-close-dialog">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <v-card-text class="d-flex flex-column flex-grow-1">
          <div
            class="event-filtered-control__middle d-flex justify-space-between align-center flex-grow-0 flex-shrink-0 px-5"
            style="margin-left: -24px; margin-right: -24px; overflow-x: hidden"
          >
            <div class="d-flex align-center">
              <img
                :src="activeEvent.icon"
                alt="event"
                style="width: 66px; height: 66px"
                data-testid="event-icon-big"
              />
            </div>

            <div
              class="align-center"
              style="width: 65%; justify-self: flex-end"
            >
              <PlaylistVolumeList v-if="activeEvent.is_started" />
            </div>

            <div
              class="event-filtered-control__start-stop d-flex justify-start"
            >
              <div>
                <v-btn
                  color="#434343"
                  x-large
                  class="event-filtered-control__start-stop"
                  v-if="!activeEvent.is_started"
                  @click="startStopCurrEvent(true)"
                  :loading="loadingStartStopEvent"
                  :disabled="!isSuperUser && !$can('event.start', 'ws_event')"
                  data-testid="btn-start-event"
                >
                  <v-icon left size="25"> mdi-play-circle </v-icon>
                  Start Event
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn
                  color="#434343"
                  x-large
                  class="event-filtered-control__start-stop"
                  v-if="activeEvent.is_started"
                  @click="startStopCurrEvent(false)"
                  :loading="loadingStartStopEvent"
                  :disabled="!isSuperUser && !$can('event.stop', 'ws_event')"
                  data-testid="btn-stop-event"
                >
                  <v-icon left size="25"> mdi-stop-circle </v-icon>
                  Stop Event
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row
            class="flex-grow-1"
            style="margin-left: -24px; margin-right: -24px"
          >
            <v-col
              cols="4"
              sm="4"
              md="4"
              class="ml-0 pl-0 pb-0"
              style="padding-right: 20px"
            >
              <v-card
                class="mx-auto event-members-wrapper"
                outlined
                color="#282A2A"
                style="height: 100%"
              >
                <v-card-title
                  class="event-name pa-5"
                  data-testid="event-name-2"
                >
                  {{ activeEvent.name }}
                </v-card-title>

                <v-card
                  class="mx-auto event-content-block"
                  outlined
                  color="#282A2A"
                  data-testid="event-description"
                >
                  <v-card-title class="pb-1 px-5">Description</v-card-title>
                  <v-card-text class="px-5">
                    {{ activeEvent.description }}
                  </v-card-text>
                </v-card>

                <v-card
                  class="mx-auto event-content-block"
                  outlined
                  color="#282A2A"
                  data-testid="container-members"
                >
                  <v-card-title class="px-5 pb-1">Participants</v-card-title>
                  <v-card-text>
                    <template v-if="activeEvent.members.length > 0">
                      <div class="event-members">
                        <div
                          class="d-flex justify-space-between pa-2 single-member"
                          v-for="(member, index) in getMembersAvatarsData(
                            activeEvent.members
                          )"
                          :key="'event-member' + index"
                          data-testid="event-member"
                        >
                          <div class="d-flex">
                            <div class="event-members__img">
                              <v-badge
                                avatar
                                bordered
                                overlap
                                offset-x="54"
                                offset-y="33"
                              >
                                <template v-slot:badge>
                                  <v-avatar>
                                    <v-img
                                      v-if="member.flag"
                                      :src="member.flag"
                                      alt="image"
                                    ></v-img>
                                  </v-avatar>
                                </template>
                                <v-badge
                                  bordered
                                  bottom
                                  :color="
                                    member.status === 'OFFLINE'
                                      ? '#FF808B'
                                      : 'green accent-4'
                                  "
                                  :content="
                                    ['JOINED', 'READY'].includes(member.status)
                                      ? member.status.charAt(0)
                                      : ''
                                  "
                                  offset-x="10"
                                  offset-y="10"
                                  class="badge-member-status"
                                >
                                  <v-avatar size="40">
                                    <v-img :src="member.icon"></v-img>
                                  </v-avatar>
                                </v-badge>
                              </v-badge>
                            </div>
                            <div>
                              <div
                                class="d-flex flex-column justify-space-between"
                              >
                                <div class="event-members__title">
                                  {{ member.engine ? member.engine.name : "-" }}
                                </div>
                                <div class="event-members__subtitle">
                                  {{ getMemberSubtitle(member) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="justify-self: flex-end">
                            <div style="width: 40px">
                              <v-tooltip
                                top
                                v-if="
                                  member.output_permission &&
                                  !member.output_state
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    @click.stop="
                                      permissionHandshakeOutput(member)
                                    "
                                    :color="Pallette.actionButtons.play"
                                    dark
                                    fab
                                    x-small
                                    v-on="on"
                                    style="margin: 0 auto"
                                    data-testid="btn-allow-output-permission"
                                  >
                                    <v-icon>mdi-hand-back-right-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Allow Output Permission</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <v-btn
                      v-if="!link_hash"
                      text
                      color="#757779"
                      class="btn-add-participant mt-2"
                      block
                      @click="addMember()"
                      data-testid="btn-add-member"
                    >
                      <div class="number-circle">+</div>
                      Add participant
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>

            <v-col cols="8" sm="8" md="8" class="mb-0 pb-0 px-0">
              <EventPlaylistsList v-if="activeEvent.is_started" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <EventMemberDialog
      :dialog.sync="dialogAddMember"
      attach="#filtered-events-wrap"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import WsConnMixin from "@/mixins/websocket-connection.js"
import EventMembersMixin from "@/mixins/event/members.js"
import EventCommonMixin from "@/mixins/event/common.js"
import EventPlaylistsList from "./EventPlaylistsList"
import EventStatusChip from "../../EventStatusChip"
import EventMemberDialog from "@/views/store/events/EventMemberDialog"
import PlaylistVolumeList from "../../volume/PlaylistVolumeList"

export default {
  props: {
    event_key: {
      type: [Number, String],
      default: -1,
    },
    link_hash: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    PlaylistVolumeList,
    EventPlaylistsList,
    EventStatusChip,
    EventMemberDialog,
  },

  mixins: [WsConnMixin, EventCommonMixin, EventMembersMixin],

  data: () => ({
    dialog: true,
    dialogAddMember: false,
  }),

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEventItem").then(() => {
      self.getItemData().then(() => {
        self.$store.dispatch("setMembersRcData").then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    })
    self.$root.$off([
      "event-page-refresh",
      "events-subscribe-playlist",
      "event-member-change",
    ])
    // there are some changes in member statuses - we need to update them
    self.$root.$on("event-page-refresh", (data) => {
      self.$store.dispatch("refreshEventMembersStatuses", {
        slug: self.event_key,
      })
      self.$store.dispatch("setMembersRcData")
    })
    self.$root.$on("events-subscribe-playlist", (data) => {
      self.$store.dispatch("refreshEventMembersPlaylists", data)
    })
    self.$root.$on("event-member-change", () => {
      self.getItemData().then(() => {
        self.$store.dispatch("setMembersRcData")
      })
    })
    self.$on("event-list", () => {
      self.getItemData().then(() => {
        self.$store.dispatch("setMembersRcData")
      })
    })
  },

  mounted() {
    let self = this
    self.initWebsocketConnection({ playlist: true })
  },

  methods: {
    closeDialog() {
      !this.link_hash
        ? this.$router
            .push({ path: `/store/events`, query: this.$route.query })
            .catch((err) => {})
        : this.$router
            .push({
              path: `/events/access/${this.link_hash}`,
              query: this.$route.query,
            })
            .catch((err) => {})
    },
    getItemData() {
      return this.$store.dispatch("getEventItem", {
        slug: this.event_key,
      })
    },
    startStopCurrEvent(action = false) {
      let self = this
      self.$store.dispatch("loadingStartStopEvent", true).then(() => {
        self.$root.$emit("eStartStopCurrentEvent", action)
      })
    },
    getMemberSubtitle(member) {
      if (member?.location?._key) {
        return member?.location?.name ? member?.location?.name : "-"
      }
      return member.engine ? member.engine.serial_number : "-"
    },
    addMember() {
      this.dialogAddMember = true
    },
    permissionHandshakeOutput(item) {
      let self = this
      self.$store
        .dispatch("handshakeEventMember", {
          slug: {
            member_key: item._key,
            event_key: self.activeEvent._key,
          },
        })
        .then(() => {
          self.$root.$emit("event-member-change")
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      isSuperUser: "isSuperUser",
      Pallette: "get_Pallette",
      loadingStartStopEvent: "loadingStartStopEvent",
    }),
    isActiveConnectionsTab() {
      return this.isSuperUser && !this.activeEvent.group_key
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/moon/components/events/event-common.scss";
@import "@/assets/scss/moon/components/events/event-filtered-control.scss";
</style>
