<template>
  <div id="playlists-list-wrapper" data-testid="container-playlists-list">
    <slider ref="slider" :options="options">
      <template v-for="(member, index) in membersEvent">
        <slideritem
          v-if="member.playlist"
          :style="sliderStyle(index)"
          :key="member._key"
        >
          <EventPlaylist :member="member" />
        </slideritem>
      </template>
      <div slot="loading">{{ activeEvent.is_started ? "loading..." : "" }}</div>
    </slider>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EventPlaylist from "./EventPlaylist"
import { slider, slideritem } from "vue-concise-slider"

export default {
  components: {
    EventPlaylist,
    slider,
    slideritem,
  },

  data: () => ({
    //Slider configuration [obj]
    options: {
      currentPage: 0,
      thresholdDistance: "200",
      loopedSlides: 2,
    },
  }),

  methods: {
    sliderStyle(index) {
      let styles = {
        //'width': `${this.sliderWidth}%`,
        width: "auto",
        "margin-right": "20px",
      }
      return styles
    },
  },

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
    }),
    membersEvent: {
      get() {
        return this.activeEvent.members
      },
      set(val) {},
    },
    sliderWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100
        case "sm":
          return 100
        case "md":
          return 75
        case "lg":
          return 49
        case "xl":
          return 49
        default:
          return 100
      }
    },
  },
}
</script>

<style lang="scss">
#playlists-list-wrapper {
  width: 100%;
  height: 100%;
}
.slider-pagination-bullet {
  background: #fff none repeat scroll 0 0 !important;
}
</style>
